import { HTMLSerializer, RichText, RichTextBlock } from 'prismic-reactjs'
import { ReactNode, useMemo } from 'react'

interface Props {
  children?: string | RichTextBlock[] | any[]
  htmlSerializer?: HTMLSerializer <ReactNode>
}

const validate = (value: any) => {
  if (!value) {
    return { type: 'empty' }
  } else if (typeof value === 'string') {
    return { type: 'string', value }
  } else {
    return { type: 'rich-text', value }
  }
}

export const PrismicRichText = ({ children, htmlSerializer }: Props) => {
  const { type, value } = useMemo(() => validate(children), [children])

  return (
    <>
      {type === 'string' && (
        <div>{value}</div>
      )}
      {type === 'rich-text' && (
        <RichText
          render={value}
          htmlSerializer={htmlSerializer}
        />
      )}
    </>
  )
}
